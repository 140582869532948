<template>
  <div class="flex flex-col w-full">
    <div class="w-full" v-if="!showError">
      <card class="w-full flex flex-col mt-2 p-5">
        <div class="w-full gap-5 mb-6 flex">
          <Card class="w-3/6 py-6 px-4" v-if="stats">
            <div class="flex justify-between">
              <Icon
                class-name="text-white "
                icon-name="people-union"
                size="l"
              />
              <div class="flex flex-col">
                <span class="text-xs font-semibold uppercase text-jet"
                  >Pending Requests</span
                >
                <span class="text-lg font-extrabold text-carrotOrange">{{
                  stats.pendingRequests
                }}</span>
              </div>
              <div class="border bg-white w-0" />
              <div class="flex flex-col">
                <span class="text-xs font-semibold uppercase text-jet"
                  >Approved Requests</span
                >
                <span class="text-lg font-extrabold text-mediumSeaGreen">{{
                  stats.approvedRequests
                }}</span>
              </div>
              <div class="border bg-white w-0" />
              <div class="flex flex-col">
                <span class="text-xs font-semibold uppercase text-jet"
                  >Disapproved Requests</span
                >
                <span class="text-lg font-extrabold text-desire">
                  {{ stats.disapprovedRequests !== undefined ? stats.disapprovedRequests : stats.dissapprovedRequests }}
                </span>
              </div>
            </div>
          </Card>
        </div>
        <template v-slot:footer>
          <CardFooter
            reloadcard
            printcard
            @reload="reload"
            @actionModal="viewFilterTable($event)"
            @searchResult="handleSearch($event)"
          />
        </template>
      </card>

      <card class="w-full mt-10">
        <div class="flex flex-col">
          <div
            class="w-full flex flex-col"
            v-if="requests.length !== 0 || loading"
          >
            <Table
              :headers="header"
              :items="requests"
              class="w-full mt-4 mb-4"
              :has-number="true"
              :loading="loading"
              :pagination-list="metaData"
              @page="handlePage($event)"
              page-sync
              @itemsPerPage="handleItemsPerPage($event)"
            >
              <template v-slot:item="{ item }">
                <div
                  v-if="item.employee"
                  class="flex content-center"
                  style="width: 200px"
                >
                  <div>
                    <img
                      class=""
                      :src="item.data.employee.photo"
                      v-if="
                        item.data.employee.photo !== '' ||
                        item.data.employee.photo
                      "
                      style="height: 30px; width: 30px; border-radius: 5px"
                    />
                    <div
                      style="height: 30px; width: 30px; border-radius: 5px"
                      class="text-blueCrayola border text-center font-semibold pt-2"
                      v-else
                    >
                      {{
                        $getInitials(
                          `${item.data.employee.fname} ${item.data.employee.lname}`
                        )
                      }}
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <span
                      class="font-bold cursor-pointer ml-2 capitalize"
                      style="color: #321c3b"
                      >{{ item.data.employee.fname }}
                      {{ item.data.employee.lname }}</span
                    >
                    <span
                      class="cursor-pointer ml-2 capitalize text-gray-500"
                      >{{ item.data.employee.designation ? item.data.employee.designation.name : '-' }}</span
                    >
                  </div>
                </div>
                <div v-if="item.createdAt" class="capitalize">
                  <span>
                    {{
                      $DATEFORMAT(
                        new Date(item.data.createdAt),
                        "MMMM dd, yyyy"
                      )
                    }}
                  </span>
                </div>
                <div v-if="item.principalAmount">
                  <span>
                    {{ convertToCurrency(item.data.principalAmount) }}
                  </span>
                </div>
                <div v-if="item.interestRate">
                  <p>
                    {{ item.data.interestRate }}
                  </p>
                </div>
                <div v-if="item.interestAmount">
                  <p>
                    {{ convertToCurrency(item.data.interestAmount) }}
                  </p>
                </div>
                <div v-if="item.repaymentAmount">
                  <p>
                    {{ convertToCurrency(item.data.repaymentAmount) }}
                  </p>
                </div>
                <div v-if="item.repaymentDuration">
                  <p>
                    {{ item.data.repaymentDuration }}
                  </p>
                </div>
                <div
                  v-if="item.approvalStatus"
                  style="width: 100px"
                  class="p-3"
                  :class="{
                    pending: item.data.approvalStatus === 'pending',
                    approved: item.data.approvalStatus === 'approved',
                    disapproved: item.data.approvalStatus === 'disapproved',
                  }"
                >
                  <div class="w-auto capitalize flex flex-col">
                    <p class="text-sm font-semibold">
                      {{ item.data.approvalStatus | capitalize }}
                    </p>
                  </div>
                </div>
                <div v-else-if="item.id">
                  <Menu left top="-150" margin="24" class="my-2 p-0">
                    <template v-slot:title>
                      <icon icon-name="more_icon" size="xs" />
                    </template>
                    <div
                      class="w-48 flex flex-col justify-start items-center gap-3 px-3 py-2"
                    >
                      <div
                        class="w-full"
                        @click="checkPrivileges('viewDetails', item.data.id)"
                      >
                        <div
                          class="w-full flex justify-start items-center gap-2 cursor-pointer"
                        >
                          <Icon
                            icon-name="icon-eye"
                            class-name="text-blueCrayola"
                            size="xs"
                          />
                          <p class="font-normal text-sm text-darkPurple">
                            View Details
                          </p>
                        </div>
                      </div>
                      <div
                        class="w-full"
                        @click="checkPrivileges('approval', item.data.id)"
                      >
                        <div
                          class="w-full flex justify-start items-center gap-2 cursor-pointer"
                        >
                          <Icon
                            icon-name="icon-upgrade"
                            class-name="text-mediumSeaGreen"
                            size="xs"
                          />
                          <p class="font-normal text-sm text-darkPurple">
                            Approval Status
                          </p>
                        </div>
                      </div>
                    </div>
                  </Menu>
                </div>
              </template>
            </Table>
          </div>
          <div
            class="w-full flex flex-col mt-4 p-2 justify-center items-center"
            v-else
          >
            <p class="text-base my-2">
              There are no Loans requests at the moment.
            </p>
          </div>
        </div>
      </card>
    </div>
    <ErrorPage v-else />

    <FilterTable
      v-if="filterTable"
      :filter-data="filterArray"
      @close="closeFilter($event)"
      @getParams="searchFilter($event)"
    />

    <advance-timeline
      :timeline-data="advanceTimeline"
      :open-modal="openModal"
      @close="openModal = false"
      slug="Loan"
      v-if="openModal"
    />
    <ApprovalProgress ref="approval_status" :mode="mode" />
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";
import FilterTable from "@/components/FilterTable";
import ErrorPage from "@/modules/Admin/error403";
import AdvanceTimeline from "@/components/modals/AdvanceTimeline";
import Menu from "@/components/Menu";

export default {
  name: "Advance",
  components: {
    Table,
    Card,
    Icon,
    FilterTable,
    Menu,
    AdvanceTimeline,
    CardFooter,
    ErrorPage,
    ApprovalProgress: () => import("@/components/ApprovalStatus"),
  },
  props: {
    mode: {
      type: String,
      default: "admin",
    },
  },
  data() {
    return {
      showError: false,
      openModal: false,
      header: [
        { title: "EMPLOYEE", value: "employee" },
        { title: "Date Requested", value: "createdAt" },
        // { title: "Request Type", value: "requestType" },
        { title: "Principal Amount", value: "principalAmount" },
        { title: "Interest Rate (P/A)", value: "interestRate" },
        { title: "Interest Amount", value: "interestAmount" },
        { title: "Total Repayable", value: "repaymentAmount" },
        { title: "Repayment Period", value: "repaymentDuration" },
        { title: "Status", value: "approvalStatus" },
        { title: "", value: "id", image: true },
      ],
      requests: [],
      initialRequests: [],
      searchWord: null,
      stats: {
        pendingRequests: "--",
        approvedRequests: "--",
        disapprovedRequests: "--",
      },
      query: "type=loan",
      loading: true,
      filterArray: [
        { header: "Location(s)", value: "locationId", optionsBox: [] },
        { header: "Level(s)", value: "levelId", optionsBox: [] },
        { header: "Function(s)", value: "functionId", optionsBox: [] },
        { header: "Designation(s)", value: "designationId", optionsBox: [] },
        { header: "Status", value: "status", optionsBox: [] },
      ],
      filterTable: false,
      metaData: {},
      itemsPerPage: 50,
      numberOfPage: 1,
    };
  },
  methods: {
    async checkPrivileges(slug, id) {
      switch (slug) {
        case "approval":
          try {
            if (this.mode === 'admin') {
              await this.$handlePrivilege("loan", "viewApprovalFlow");
            }
            this.openApprovalModal(id);
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
          break;
        case "viewDetails":
          try {
            if (this.mode === 'admin') {
              await this.$handlePrivilege("loan", "viewDetails");
            }
            this.openSideModal(id);
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
          break;
        default:
          break;
      }
    },
    openSideModal(id) {
      const findRequest = this.initialRequests.find(
        (item) => item.request.id === id
      );
      if (findRequest) {
        this.advanceTimeline = this.getTimelineRequest(findRequest);
        this.openModal = true;
      } else {
        this.$toasted.error("Request not found!");
      }
    },
    openApprovalModal(id) {
      this.$refs.approval_status.toggle(id);
    },
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    handleSearch(searchWord) {
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },

    queryUpdate() {
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getRequests(`${pageNumber}${itemPage}${search}`);
    },
    getTimelineRequest(result) {
      let outstandingSum = 0;
      const scheduleData = result.request.repaymentSchedule.map((v) => ({
        scheduleDate: v.repaymentDate,
        amount: v.totalAmount,
        status: v.paymentStatus,
      }));
      this.repaymentSchedule = scheduleData;

      scheduleData.forEach((a) => {
        if (a.status === "pending") {
          outstandingSum += a.amount;
        }
      });
      return {
        paySetting: result.request.paySetting.nameOnPayslip,
        rate: result.request.interestRate,
        amount: result.request.principalAmount,
        repayment: result.request.repaymentAmount,
        advanceType: result.request.type,
        duration: result.request.repaymentDuration,
        repaymentTime: result.request.firstRepaymentDate,
        requestDate: result.request.createdAt,
        status: result.request.approvalStatus,
        outstanding: outstandingSum,
        repaymentSchedule: scheduleData,
        ...result.employee,
      };
    },

    getRequests(params) {
      this.loading = true;
      if (this.mode !== "ess") {
        this.$_getAdminEmployeesLoan(params)
          .then(({ data }) => {
            this.requests = data.data;
            this.initialRequests = data.data;
            this.requests = this.requests.map((element) => ({
              employee: element.employee,
              ...element.request,
            }));
            this.metaData = data.meta;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.$_getManagerEmployeesLoan(params)
          .then(({ data }) => {
            this.requests = data.data;
            this.initialRequests = data.data;
            this.requests = this.requests.map((element) => ({
              employee: element.employee,
              ...element.request,
            }));
            this.metaData = data.meta;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    getStats() {
      if (this.mode === "ess") {
        this.$_getManagerEmployeesLoanSummary().then((response) => {
          this.stats = response.data;
        });
      } else {
        this.$_getAdminEmployeesLoanStats().then((response) => {
          this.stats = response.data;
        });
      }
    },

    viewFilterTable(value) {
      if (value) {
        this.filterTable = true;
      }
    },

    reload() {
      this.requestLoan();
    },

    async requestLoan() {
      if (this.mode === "ess") {
        this.showError = false;
        this.getRequests("&page=1&perPage=50");
        this.getStats();
        return;
      }
      try {
        await this.$handlePrivilege("loan", "viewRegister");
        this.showError = false;
        this.getRequests("&page=1&perPage=50");
        this.getStats();
      } catch (error) {
        this.showError = true;
      }
    },

    getAllFunctions() {
      this.$_getFunction().then((response) => {
        response.data.functions.map((functionVal) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Function(s)") {
              filter.optionsBox.push({
                id: functionVal.id,
                name: functionVal.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllDesignations() {
      this.$_getDesignations("").then((response) => {
        response.data.designations.map((designation) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Designation(s)") {
              filter.optionsBox.push({
                id: designation.id,
                name: designation.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllLocations() {
      this.$_getHumanarLocations("").then((response) => {
        response.data.outlets.map((outlet) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Location(s)") {
              filter.optionsBox.push({
                id: outlet.id,
                name: outlet.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllLevels() {
      this.$_getLevels().then((response) => {
        response.data.levels.map((outlet) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Level(s)") {
              filter.optionsBox.push({
                id: outlet.id,
                name: outlet.name,
              });
            }
          });
          return {};
        });
      });
    },

    getStatus() {
      this.filterArray.forEach((filter) => {
        if (filter.header === "Status") {
          filter.optionsBox.push(
            {
              id: "pending",
              name: "Pending",
            },
            {
              id: "approved",
              name: "Approved",
            },
            {
              id: "disapproved",
              name: "Disapproved",
            }
          );
        }
      });
    },

    closeFilter(val) {
      if (val) {
        this.filterTable = false;
      }
    },

    searchFilter(params) {
      this.getRequests(params);
      this.filterTable = false;
    },
  },

  mounted() {
    this.requestLoan();
    this.getAllFunctions();
    this.getAllDesignations();
    this.getAllLocations();
    this.getAllLevels();
    this.getStatus();
    this.getStats();
  },
};
</script>

<style scoped>
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}
.card{
  border-radius: 5px;
  box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1);
  background-color: #3454D1;
}
</style>
